import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const HorseImg = ({ name, img }) => (
  <div className="w-full md:w-80 flex-shrink-0 rounded-2xl">
    <GatsbyImage
      className="rounded-2xl drop-shadow-xl round-img"
      alt={name}
      image={img}
    />
  </div>
)

export default HorseImg
