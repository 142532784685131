import React from "react"
import { graphql } from "gatsby"
import BaseAbout from "../../components/layout/page/base-about"
import HorseImg from "../../components/horse-list/horse-img"

const ThankYouAbout = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
    file: {
      childImageSharp: { gatsbyImageData },
    },
  } = data

  return (
    <BaseAbout title={title} html={html} htmlClass="flex justify-center">
      <div className="w-full mt-6 md:mt-8 flex justify-center">
        <HorseImg img={gatsbyImageData} name={title} />
      </div>
    </BaseAbout>
  )
}

export default ThankYouAbout

export const query = graphql`
  query ThankYouQuery {
    markdownRemark(frontmatter: { name: { eq: "thank-you" } }) {
      html
      frontmatter {
        title
      }
    }
    file(relativePath: { eq: "horses_thank-you.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
